import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { routes } from '../components/Router/routes'
import { navigate } from 'gatsby'
import { usePageViewAnalytics } from '../hooks/useAnalytics'

//page exists because this is the url DIBS links back to when payment fails.
//but DIBS shows that it failed already, so we just wanna show the checkout page, so user can try again.
const PaymentFailed = () => {
  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: 'afvist - shop',
      siteSection: 'shop',
      subSection1: 'betaling afvist',
      pageType: 'rejected',
      id: 'betaling-afvist-shop',
      pageVariant: 'SMB',
    },
  })

  useEffect(() => {
    navigate(routes.checkout.route)
  }, [])

  return (
    <Layout>
      <h1>Betaling Afvist</h1>
    </Layout>
  )
}

export default PaymentFailed
